import type { CollectionResponse } from "@/data/__generated__/types/generated";

import Image from "next/image";
import Link from "next/link";

import AddFavoriteButton from "../AddFavoriteButton";
import CountryBadge from "../ui/CountryBadge";

import { useUserContext } from "@/app/providers/UserProvider";
import { CMS_URL, LABELS_COLORS } from "@/constants";
import { getImageSource } from "@/helpers";
import useAlreadySeen from "@/hooks/useAlreadySeen";
import useFavorites from "@/hooks/useFavorites";
import { cn } from "@/libs/utils";
import { CollectionSamples } from "@/types";
import { memo, useCallback, useMemo } from "react";

type CollectionCardProp = {
	className?: string;
	collection: CollectionResponse;
};

const CollectionCard: React.FC<CollectionCardProp> = ({ collection, className }) => {
	const user = useUserContext();
	const favorites = useFavorites();
	const alreadySeen = useAlreadySeen();

	if (!collection) return null;

	const isFavorite = favorites.collections.findIndex((item) => item.id === collection.id) > -1;
	const thumbnailsLength = collection.options?.thumbnails?.length || 0;
	const showMoreButton = thumbnailsLength > 5;

	const onFavoriteClick = useCallback(() => {
		if (isFavorite) {
			favorites.removeItem({ id: collection.id, type: "collection" });
		} else {
			favorites.addItem({ data: collection, type: "collection" });
		}
	}, [collection.id, favorites, isFavorite]);

	const addToSeenStorage = useCallback(() => {
		alreadySeen.addItem({ data: collection, type: "collection" });
	}, [collection.id, alreadySeen]);

	const collectionSamples = useMemo(
		() =>
			collection.labels.filter(
				(label) => label.title === CollectionSamples.east || label.title === CollectionSamples.north
			),
		[collection.labels]
	);

	const isMinPrice = useMemo(
		() => collection.options?.minPrice && collection.options?.minPrice !== "0",
		[collection.options?.minPrice]
	);

	return (
		<div
			className={cn(
				"w-full rounded-3xl overflow-hidden bg-white hover:shadow-md transition duration-300 sm:w-full",
				className
			)}
		>
			<div className="h-[192px] relative sm:h-[120px]">
				<Link href={`/catalog/${collection.slug}`} onClick={addToSeenStorage}>
					<Image
						width={400}
						height={272}
						alt={collection.slug}
						className="h-full w-full object-cover"
						src={getImageSource({
							height: 272,
							width: 400,
							type: "collections",
							brand: collection.slug,
							thumbnail: collection.image || "",
						})}
					/>
				</Link>
				<CountryBadge className="absolute top-3 left-3" flag={collection.countrySlug} title={collection.countryTitle} />
				<AddFavoriteButton isFavorite={isFavorite} onClick={onFavoriteClick} className="absolute top-0 right-0" />
				{user && (
					<a
						rel="noopener noreferrer"
						href={`${CMS_URL}/_b/collection/${collection.id}/edit`}
						target="_blank"
						className={cn(
							"bg-primaryBlack/50 backdrop-blur-sm px-4 absolute bottom-1 right-1 flex text-white rounded-md"
						)}
					>
						<span>Редактировать</span>
					</a>
				)}
			</div>
			<Link href={`/catalog/${collection.slug}`} onClick={addToSeenStorage}>
				<div className="px-4 flex items-center gap-2 mt-2">
					{collection.options.thumbnails?.slice(0, 4).map((thumbnail) => {
						return (
							<Image
								className="w-7 h-7 rounded-lg border sm:w-6 sm:h-6"
								src={thumbnail}
								alt={thumbnail}
								key={thumbnail}
								width={27}
								height={27}
							/>
						);
					})}
					{showMoreButton && (
						<div className="flex items-center justify-center border rounded-lg h-7 min-w-[40px] border-primaryBlack sm:hidden">
							<span className="font-medium relative top-px text-sm text-primaryBlack">+{thumbnailsLength - 4}</span>
						</div>
					)}
				</div>
				<div className="flex-grow p-4 flex flex-col justify-between">
					<span className="font-medium text-lg sm:text-sm">{collection.title}</span>
					<div className="flex items-center text-sm mt-1 sm:flex-col sm:items-start sm:gap-1">
						<span className="text-darkGray mr-1">Бренд</span>
						<span className="text-primaryBlack">{collection.manufacturerTitle}</span>
					</div>
					<div className="flex items-center text-darkGray mt-1 text-sm">
						<span>Размеры</span>
						<div className="truncate">
							{collection.options?.sizes?.map((size) => {
								return (
									<span className="ml-1" key={size}>
										{size}
									</span>
								);
							})}
						</div>
					</div>
					{collectionSamples.length > 0 && (
						<div className="flex items-center text-darkGray mt-1 text-sm">
							<span>Образцы</span>
							<div className="truncate flex items-center gap-1 ml-2">
								{collectionSamples.map((sample) => {
									return (
										<span
											style={{
												backgroundColor: LABELS_COLORS[sample.title as keyof typeof LABELS_COLORS],
											}}
											className=" text-white text-xs font-medium py-[3px] px-2 rounded-full"
											key={sample.title}
										>
											{sample?.title?.replaceAll("Образец", "")}
										</span>
									);
								})}
							</div>
						</div>
					)}
					{isMinPrice && (
						<div className="font-medium text-lg mt-4 text-primaryBlack">
							<span className="font-medium">{`от ${collection.options?.minPrice} руб.`}</span>
						</div>
					)}
				</div>
			</Link>
		</div>
	);
};

export default memo(CollectionCard);
